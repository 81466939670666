import React, { useEffect } from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ContactPage = () => {
  const formId = 'contact';

  useEffect(() => {
    window.submitContact = () => {
      document.getElementById(formId).submit();
    };
  });

  const onClick = event => {
    if (document.getElementById(formId).checkValidity()) {
      event.preventDefault();
      window.grecaptcha.reset();
      window.grecaptcha.execute();
    }
  };

  return (
    <Layout>
      <SEO title="Contact" />
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js" async defer />
      </Helmet>
      <h1 className="title">Contact</h1>
      <hr />
      <div className="content">
        <p>I'd love to hear from you, so much that I'll get back to you within a few hours.</p>
      </div>
      <div className="columns">
        <div className="column">
          <form id={formId} name={formId} method="post" action="/.netlify/functions/hello">
            <div className="field">
              <label className="label" htmlFor="name">
                Name
              </label>
              <div className="control">
                <input className="input" type="text" id="name" name="name" required />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="email">
                Email
              </label>
              <div className="control">
                <input className="input" type="text" id="email" name="email" required />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="message">
                Message
              </label>
              <div className="control">
                <textarea className="textarea" id="message" name="message" required />
              </div>
            </div>
            <div className="field is-grouped">
              <div className="control">
                <div
                  className="g-recaptcha"
                  data-sitekey="6Lct_NYUAAAAAFOoCLfyW5mHpUN8x3bbsBGHl-UN"
                  data-size="invisible"
                  data-callback="submitContact"
                />
                <button className="button is-link" onClick={onClick}>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="column" />
      </div>
    </Layout>
  );
};

export default ContactPage;
